/*--------------------------------------------------------------
24. Testimonial
----------------------------------------------------------------*/
.st-testimonial.st-style1 {
  border-radius: 7px;
  transition: all 0.4s ease;
  background-color: $white;
  position: relative;
  padding: 30px 30px;
  border: 1px solid $black4;

  .st-quote {
    position: absolute;
    bottom: -6px;
    right: 0;
    font-size: 50px;
    line-height: 1em;
    color: #f5f5f5;
    transition: all 0.4s ease;
  }

  &:hover {
    border-color: transparent;
    box-shadow: 0px 1px 30px 0px rgba(112, 112, 112, 0.15);

    .st-quote {
      color: $blue;
    }

    .st-testimonial-name {
      color: $blue;
    }
  }

  .st-testimonial-text {
    text-align: center;
  }

  .st-testimonial-info {
    align-items: center;
    text-align: center;
    margin-bottom: 17px;
  }

  .st-testimonial-img {
    flex: none;
    height: 90px;
    width: 90px;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .st-testimonial-name {
    font-size: 20px;
    margin-bottom: 1px;
    transition: all 0.4s ease;
  }

  .st-testimonial-designation {
    line-height: 1.3em;
  }

  &.st-type1 {
    border: none;
    background-color: #eaf9fa;

    .st-quote {
      bottom: initial;
      top: 7px;
      left: 10px;
      right: initial;
      color: rgba($light-blue, 0.5);
    }

    &:hover {
      background-color: #eaf9fa;
      box-shadow: none;

      .st-quote {
        color: $light-blue;
      }

      .st-testimonial-name {
        color: $light-blue;
      }
    }

    &.st-color1 {
      border: none;
      background-color: #f8f8fb;

      .st-quote {
        color: rgba($pink, 0.1);
      }

      &:hover {
        background-color: #f8f8fb;
        box-shadow: none;

        .st-quote {
          color: $pink;
        }

        .st-testimonial-name {
          color: $pink;
        }
      }
    }
  }
}
