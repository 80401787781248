/*--------------------------------------------------------------
5. Header
----------------------------------------------------------------*/
.st-top-header {
  overflow: hidden;
  border-bottom: 1px solid rgba($blue, 0.4);

  .st-top-header-in {
    height: 45px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s ease;
  }
}

.st-sticky-active {
  .st-top-header-in {
    height: 0 !important;
  }

  .st-top-header {
    margin-top: -1px;
  }
}

.st-top-header-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  svg {
    width: 18px;
    margin-right: 10px;
    fill: $black3;
  }

  li {
    margin-right: 35px;
    color: $black1;
    font-size: 14px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-right: 0;
    }

    i {
      margin-right: 10px;
    }

    a:hover {
      color: $blue;
    }

    span {
      color: $black3;
      font-weight: 500;
    }
  }
}

.st-top-header-btn {
  font-size: 15px;
  font-weight: 700;
  color: $blue;

  &:hover {
    color: $black1;
  }

  &.st-type1 {
    background-color: $blue;
    color: #fff;
    padding: 11px 25px;
  }
}

.st-site-header.st-style1 {
  background-color: rgba($white, 0.9);
  box-shadow: 0 10px 10px -10px rgba(33, 43, 53, 0.1);
  transition: all 0.4s ease;

  .st-main-header-in {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 80px;
  }

  &.st-sticky-active {
    background-color: $white;
  }

  .st-social-btn.st-style1 li {
    font-size: 18px;
    color: $black3;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &.st-color1 {
    .st-top-header-btn.st-type1 {
      background-color: $pink;

      &:hover {
        background-color: #e6274d;
      }
    }

    .st-top-header {
      border-color: rgba($pink, 0.3);
    }
  }
}

.st-site-header.st-style1.st-type1 {
  position: relative;
  z-index: 11;
  background: $white;
}

.st-site-header.st-style1.st-type2 {
  box-shadow: none;

  .st-top-header {
    background-color: #eaf9fa;
    border: none;
  }

  .st-top-header-in {
    height: 50px;
  }

  .st-main-header {
    box-shadow: 0px 0px 50px 0px rgba(10, 32, 70, 0.1);
  }

  .st-btn.st-style1 {
    font-size: 14px;
    padding: 0.8em 2em;
  }

  .st-main-header-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.st-site-header.st-style1.st-color2 {
  .st-top-header-in,
  .st-nav .st-nav-list > li > a {
    color: $black1;

    &.active {
      color: $light-blue;
    }
  }

  .st-nav .st-nav-list > li > a {
    &:hover {
      color: $light-blue;
    }
  }

  .st-nav .st-nav-list > li > a {
    &:before {
      background-color: $light-blue;
    }
  }
}

@media screen and (max-width: 991px) {
  .st-main-header .container {
    max-width: 100%;
  }

  .st-top-header {
    display: none;
  }

  .st-site-header.st-style1 {
    background-color: $white;
  }

  .st-site-header.st-style1 {
    .st-nav {
      display: flex;
    }
  }

  .st-site-header.st-style1.st-type1 {
    .st-btn {
      display: inline-flex !important;
    }
  }
}

// ======== Main Menu Style ========
.st-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.st-site-header.st-style1.st-type1 {
  .st-top-header {
    overflow: hidden;
    border-bottom: 1px solid $black4;

    .st-top-header-in {
      height: 70px;
    }
  }

  .st-main-header-in {
    height: 70px;
  }
}

@media screen and (min-width: 992px) {
  .st-main-header {
    position: relative;

    .container-fluid {
      padding-right: 40px;
      padding-left: 40px;
    }
  }

  .st-nav {
    display: flex;
    align-items: center;

    > .st-btn {
      margin-left: 35px;

      &.st-style2 {
        font-size: 14px;
        padding: 0.43em 1.6em;
        border-radius: 2.5em;
      }
    }

    .st-nav-list {
      display: flex !important;
      flex-wrap: wrap;

      > li {
        margin-right: 35px;

        &:last-child {
          margin-right: 0;
        }

        > a {
          padding: 15px 0;
          display: inline-block;
          font-size: 14px;
          font-weight: 600;
          color: $black2;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);
            height: 2px;
            width: 0%;
            background-color: $blue;
            transition: all 0.3s ease;
          }

          &:hover {
            color: $black1;
          }
        }

        > ul {
          left: 0;
          top: calc(100% + 15px);

          &:before {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            border: 1px solid #eaeaea;
            transform: rotate(45deg);
            background-color: #fff;
            border-right: none;
            border-bottom: none;
            top: -6px;
            left: 16px;
          }
        }

        &:hover {
          > ul {
            top: 100%;
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
          }
        }
      }

      li {
        position: relative;

        a {
          &.active {
            color: $blue;

            &:before {
              width: 100%;
            }
          }
        }
      }

      ul {
        width: 250px;
        background-color: #fff;
        position: absolute;
        border: 1px solid $black4;
        border-radius: 7px;
        padding: 10px 0;
        z-index: 100;
        opacity: 0;
        visibility: hidden;
        display: block !important;
        transition: all 0.1s ease;

        li {
          &:hover {
            ul {
              top: 0px;
            }

            > ul {
              opacity: 1;
              visibility: visible;
              transition: all 0.4s ease;
            }
          }
        }

        a {
          font-size: calc(100% - 1px);
          display: block;
          line-height: 1.6em;
          padding: 9px 20px;

          &:hover {
            background-color: $black5;
          }
        }

        ul {
          top: 15px;
          left: 100%;
        }
      }
    }
  }

  .st-munu-toggle,
  .st-munu-dropdown-toggle {
    display: none;
  }

  .st-site-header.st-style1 {
    &.st-color1 {
      .st-nav .st-nav-list > li > a {
        color: $black1;
      }

      .st-nav .st-nav-list > li > a:before {
        background-color: $pink;
      }

      .st-nav .st-nav-list li a.active,
      .st-nav .st-nav-list > li > a:hover,
      .st-top-header-list li a:hover {
        color: $pink;
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .st-munu-dropdown-toggle {
    position: absolute;
    height: 30px;
    width: 30px;
    right: 20px;
    top: 5px;

    &:before,
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 2px;
      width: 10px;
      background-color: $black2;
      transition: all 0.3s ease;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    &.active {
      &:before {
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }

  .st-nav {
    .st-nav-list {
      position: absolute;
      width: 100vw;
      left: 0;
      background-color: #fff;
      padding: 10px 0;
      display: none;
      top: 100%;
      border-top: 1px solid $black4;
      border-bottom: 1px solid $black4;
      overflow: auto;
      max-height: calc(100vh - 80px);

      ul {
        padding-left: 15px;
        display: none;
      }

      a {
        display: block;
        padding: 8px 20px;
      }
    }

    .menu-item-has-children {
      position: relative;
    }
  }

  /*Mobile Menu Button*/
  .st-munu-toggle {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 27px;
    margin: 15px 0px 15px 0px;
    cursor: pointer;

    span,
    span:before,
    span:after {
      width: 100%;
      height: 2px;
      background-color: #666;
      display: block;
    }

    span {
      margin: 0 auto;
      position: relative;
      top: 12px;
      transition-duration: 0s;
      transition-delay: 0.2s;

      &:before {
        content: '';
        position: absolute;
        margin-top: -8px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }

      &:after {
        content: '';
        position: absolute;
        margin-top: 8px;
        transition-property: margin, transform;
        transition-duration: 0.2s;
        transition-delay: 0.2s, 0s;
      }
    }
  }

  .st-toggle-active {
    span {
      background-color: rgba(0, 0, 0, 0);
      transition-delay: 0.2s;

      &:before {
        margin-top: 0;
        transform: rotate(45deg);
        transition-delay: 0s, 0.2s;
      }

      &:after {
        margin-top: 0;
        transform: rotate(-45deg);
        transition-delay: 0s, 0.2s;
      }
    }
  }
}

// ======== End Main Menu Style ========
.st-sticky-header {
  position: fixed;
  z-index: 200;
  width: 100%;
  top: 0;
  left: 0;
}

.st-site-branding {
  display: inline-block;
}

.st-opening-hr {
  margin-top: 57px;

  .st-opening-hr-title {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .st-opening-date {
    font-size: 15px;
    line-height: 1.6em;
    color: $black3;
    font-weight: 400;
    margin-bottom: -1px;
  }

  .st-opening-time {
    font-size: 15px;
    line-height: 1.6em;
  }
}

@media screen and (min-width: 991px) {
  .st-site-header.st-style2 {
    width: 300px;
    background: $white;
    height: 100vh;
    border-right: 1px solid $black4;
    overflow: auto;

    .st-nav {
      .st-nav-list {
        flex-direction: column;

        > li {
          margin-right: 0;

          > a {
            padding: 10px 0;
          }
        }
      }
    }

    .st-main-header {
      position: relative;
      padding: 40px 25px 80px;

      &:after {
        content: '';
        position: fixed;
        height: 80px;
        width: 299px;
        left: 0;
        bottom: 0;
        background-color: #fff;
      }
    }

    .st-social-btn.st-style1 {
      position: fixed;
      bottom: 40px;
      left: 40px;

      li {
        color: $black3;
        margin-right: 20px;
        font-size: 18px;

        &:last-child {
          margin-right: 0;
        }

        a {
          &:hover {
            color: $black2;
          }
        }
      }
    }

    .st-main-header-left {
      margin-bottom: 24px;
    }

    .st-btn.st-style1 {
      margin-top: 20px;
      font-size: 14px;
      padding: 0.6em 1.3em;
    }
  }

  .st-get-sidebar {
    padding-left: 300px;

    .st-sticky-footer {
      left: 300px;
      max-width: calc(100% - 300px);
    }
  }
}

@media screen and (max-width: 991px) {
  .st-site-header .st-btn,
  .st-site-header.st-style2 .st-opening-hr,
  .st-site-header.st-style2 .st-social-btn.st-style1 {
    display: none !important;
  }

  .st-site-header.st-style2 {
    background-color: $white;

    .st-nav {
      display: flex;
    }

    .st-main-header-in {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 70px;
    }
  }

  .st-site-header.st-style1.st-type2 .st-main-header-center {
    position: initial;
    transform: initial;
  }

  .st-site-header.st-style1.st-type2
    .st-main-header-center
    + .st-main-header-right {
    display: none;
  }
}
